import type * as PopoverPrimitive from '@radix-ui/react-popover';

import type { ButtonProps } from '@xing-com/button';

import * as Styled from './tip-box.styles';

const { variants } = Styled;

export type TipBoxSideProps = 'top' | 'right' | 'bottom' | 'left';
export type TipBoxVariant = keyof typeof variants;

export type TipBoxRootProps = PopoverPrimitive.PopoverProps;

export type TipBoxTriggerProps = PopoverPrimitive.PopoverTriggerProps & {
  children: React.ReactNode;
};

export type TipBoxContentProps = {
  /** The content of the TipBox */
  children: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Whether to show an arrow pointing to the trigger */
  showArrow?: boolean;
  /** The preferred side of the trigger to render against */
  side?: TipBoxSideProps;
  /** The variant of the TipBox */
  variant?: TipBoxVariant;
} & Omit<PopoverPrimitive.PopoverContentProps, 'variant'>;

export type TipBoxProps = {
  /** The content of the TipBox */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** The open state of the popover when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean;
  /** The element that triggers the tip box */
  trigger: React.ReactNode;
  /** Event handler called when the open state changes */
  onOpenChange?: (open: boolean) => void;
  /** The controlled open state of the popover. Must be used in conjunction with onOpenChange. */
  open?: boolean;
  /** The modality of the popover. When set to true, interaction with outside elements will be disabled and only popover content will be visible to screen readers. */
  modal?: boolean;
  /** Whether to show an arrow pointing to the trigger */
  showArrow?: boolean;
  /** Whether to show a closing indicator */
  showClosingIndicator?: boolean;
  /** The variant of the TipBox */
  variant?: TipBoxVariant;
} & Omit<PopoverPrimitive.PopoverContentProps, 'variant'>;

export type TipBoxPresetProps = {
  /** The text to display in the marker */
  markerText?: string;
  /** The text to display in the headline */
  headlineText?: string;
  /** The text to display in the body */
  bodyText?: string;
  /** The label for the button */
  buttonLabel?: string;
  /** The props for the button */
  buttonProps?: ButtonProps;
} & Omit<TipBoxProps, 'children'>;
